import {reactive,toRefs,onBeforeMount,onMounted,computed,getCurrentInstance,defineComponent,nextTick} from 'vue';
import LockScreenUtil,{ILockScreenDataObj} from "@/views/sysviews/yunwei/lockScreen/lockScreenUtil";
import music1 from '../../../../assets/music/music1.mp3';
import _3 from '../../../../assets/picResources/3.jpg';
export default defineComponent({
    name: "LockScreen",
    setup(){
        const {proxy} = getCurrentInstance() as any;
        const utils=proxy.utils;
        let store = proxy.$store;

        let dataObj:ILockScreenDataObj=reactive<ILockScreenDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            userInfo:JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("userInfo") as string)) as IStoreUserInfo,
            backMusic: music1,  //使用的音频
            picDialogVisible:false,
            imageSource:{//选择图片弹出框的图片对象
                selImageName:'',//当前选择的图片名称
                selImagePath:'', //当前选择的图片地址
                arrayPath: [],//可以选择的图片素材
            },
            backgroundImage:'url('+_3+')',
            password:'',
            marginTop:200,
            time:'',
            defaultPic:_3
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new LockScreenUtil(proxy,dataObj);
        })
        onMounted(async()=>{
            await nextTick(()=>{
                dataObj.utilInst.configPoiAndShowTime(); //设置密码输入框为主和显示时间
                dataObj.refMap.get('pwdRef').focus();//把光标定位到密码输入框中
                dataObj.utilInst.configBgAndInitImageSources();//设置锁屏背景和初始化图片数组集合
            })
        })


        //---------------------------computed------------
        const nowTime=computed(()=>{
            return dataObj.time;
        })
        //选择图片的时候，为选中的图片加上样式边框，以便区别
        const selImage=computed(()=>{
            return (params:any) => {
                return {
                    imageSelClass:dataObj.imageSource.selImagePath==params.path
                }
            }
        })
        return{
            ...toRefs(dataObj),nowTime,selImage
        }
    }
});