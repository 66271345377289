import BaseBean from "@/utils/BaseBean";

interface IImageSource{
    selImageName:string//当前选择的图片名称
    selImagePath:string //当前选择的图片地址
    arrayPath: Array<any>//可以选择的图片素材
}
export interface ILockScreenDataObj {
    utilInst:LockScreenUtil
    refMap:Map<string,any>
    userInfo:IStoreUserInfo
    backMusic: string  //使用的音频
    picDialogVisible:boolean
    imageSource:IImageSource//选择图片弹出框的图片对象
    backgroundImage:string
    password:string
    marginTop:number
    time:string
    defaultPic:string
}
export default class LockScreenUtil extends BaseBean{
    public dataObj:ILockScreenDataObj;
    constructor(proxy:any,dataObj:ILockScreenDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //设置密码输入框为主和显示时间
    public configPoiAndShowTime():void{
        //把div放在中间显示
        let height=window.innerHeight;
        this.dataObj.marginTop=(height-400)/2-200;
        let that=this;
        //当前时间
        setInterval(()=>{
            let a = new Date();
            let b = a.toLocaleTimeString();
            that.dataObj.time=b;
        },1000);
    }
    //设置锁屏背景和初始化图片数组集合
    public configBgAndInitImageSources():void{
        //看用户是否设置过背景图片
        let bgImag=localStorage.getItem('bgImag');
        //加载资源素材，方便设置背景图片
        let _imageSource=sessionStorage.getItem("imageSource") as string;
        let imageSource= JSON.parse(this.utils.UtilPub.decrypt(_imageSource)) as Array<any>;
        this.dataObj.imageSource.arrayPath=imageSource;
        //用户没有设置过背景图片并且后台请求有资源素材
        if(bgImag==null && this.dataObj.imageSource.arrayPath.length!=0)
            this.dataObj.backgroundImage='url('+this.dataObj.imageSource.arrayPath[0].path+')';
        if(bgImag!=null)this.dataObj.backgroundImage='url('+bgImag+')';
    }
    //音乐播放/暂停
    public musicEvent():void{
        if((this.dataObj.refMap.get('musicPlayRef').paused)){
            this.dataObj.refMap.get('musicPlayRef').play();
        }else{
            this.dataObj.refMap.get('musicPlayRef').pause();
        }
    }
    //登录
    public async lockLogin():Promise<void>{
        if(this.utils.UtilPub.isEmpty(this.dataObj.password)){
            this.proxy.$message('请输入密码');
        }else{
            let usercode=this.dataObj.userInfo.usercode;
            this.dataObj.password = this.utils.UtilPub.encrypt(this.dataObj.password);
            //解锁时，根据输入的密码取后台验证
            let params= {operateType:'unLockScreen',usercode:usercode,password:this.dataObj.password}
            let res = await this.utils.Api.postRequest({ url: this.utils.Api.buildUrl("/user/custom"), params: params});

            this.dataObj.password='';
            if(res.result && res.unLockScreenResult){//解锁成功，重新设置lockScreen对象到sessionStorage中
                let lockScreen=JSON.parse(sessionStorage.getItem("lockScreen")as string) as ILockScreen;
                lockScreen.lockScreen=false;
                sessionStorage.setItem("lockScreen",JSON.stringify(lockScreen));
                await this.proxy.$router.replace('/');
            }else{
                if(!res.result)this.proxy.$message(res.msg);
                if(!res.unLockScreenResult && res.unLockScreenMsg)this.proxy.$message(res.unLockScreenMsg);
            }
        }
    }
    //退出登录
    public async lockLoginOut():Promise<void>{
        this.proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        //退出登录的时候，只需要把userInfo去掉，不能把token去掉，否则报错
        sessionStorage.removeItem("userInfo");
        let res = await this.utils.Api.loginOut();
        if(res.result){
            this.utils.Tools.success({message: "成功退出登录!"});
            // await proxy.$router.replace('/login');
            location.reload();
        }else{
            this.utils.Tools.error();
        }
    }
    //图片点击事件，记录下现在点击的图片
    public imgClick(item:any,event:any):void{
        // event.target.style='border:solid;border-color:red';
        this.dataObj.imageSource.selImageName=item.preName;
        this.dataObj.imageSource.selImagePath=item.path;
    }
    //设置锁屏图片弹出框确认事件
    public picDialogSure():void{
        this.dataObj.picDialogVisible=false;
        this.dataObj.backgroundImage='url('+this.dataObj.imageSource.selImagePath+')';
        //把用户的设置放到$cookies里面，下次锁屏直接用
        localStorage.setItem('bgImag',this.dataObj.imageSource.selImagePath);
    }
}